export const hexToRgba = (hex, opacity = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )},${opacity})`;
  }
  throw new Error("Bad Hex");
};
// UP_GREEN, UP_RED, DOWN_GREEN, DOWN_RED
export const getArrowWithStatus = (status = "NO_SIGN") => {
  switch (status) {
    case "UP_RED":
      return {
        color: "red",
        rotate: null,
      };
    case "UP_GREEN":
      return {
        color: "green",
        rotate: null,
      };
    case "DOWN_GREEN":
      return {
        color: "green",
        rotate: "rotate(0.5turn)",
      };
    case "DOWN_RED":
      return {
        color: "red",
        rotate: "rotate(0.5turn)",
      };
    default:
      return {
        color: "transparent",
        rotate: null,
      };
  }
};
