import * as React from "react";

const SvgCached = (props) => (
  <svg
    width={22}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.05 16c-2.233 0-4.133-.775-5.7-2.325C3.783 12.125 3 10.233 3 8v-.175l-1.6 1.6-1.4-1.4 4-4 4 4-1.4 1.4-1.6-1.6V8c0 1.667.588 3.083 1.763 4.25C7.938 13.417 9.367 14 11.05 14c.433 0 .858-.05 1.275-.15.417-.1.825-.25 1.225-.45l1.5 1.5a8.247 8.247 0 0 1-1.95.825 7.698 7.698 0 0 1-2.05.275ZM18 11.975l-4-4 1.4-1.4 1.6 1.6V8c0-1.667-.587-3.083-1.762-4.25C14.063 2.583 12.633 2 10.95 2c-.433 0-.858.05-1.275.15-.417.1-.825.25-1.225.45l-1.5-1.5A8.25 8.25 0 0 1 8.9.275 7.696 7.696 0 0 1 10.95 0c2.233 0 4.133.775 5.7 2.325C18.217 3.875 19 5.767 19 8v.175l1.6-1.6 1.4 1.4-4 4Z"
      fill="#fff"
    />
  </svg>
);

export default SvgCached;
