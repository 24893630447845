import * as React from "react";

const SvgLogoSymbol = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={16} y={16} width={6} height={6} rx={3} fill="#00C437" />
    <rect x={2} y={2} width={6} height={6} rx={3} fill="#00C437" />
    <path
      d="M10 4.5a.5.5 0 0 0 0 1v-1ZM19 15l2.887-5h-5.774L19 15Zm-9-9.5h5v-1h-5v1ZM18.5 9v1.5h1V9h-1ZM15 5.5A3.5 3.5 0 0 1 18.5 9h1A4.5 4.5 0 0 0 15 4.5v1ZM14 19.5a.5.5 0 0 0 0-1v1ZM5 9l-2.887 5h5.774L5 9Zm9 9.5H9v1h5v-1ZM5.5 15v-1.5h-1V15h1ZM9 18.5A3.5 3.5 0 0 1 5.5 15h-1A4.5 4.5 0 0 0 9 19.5v-1Z"
      fill="#00C337"
    />
  </svg>
);

export default SvgLogoSymbol;
