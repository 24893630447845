import { AccountPage, OperationPage } from "pages";

export const ROUTES = {
  DEFAULT: "/",
  SIGN_IN: "/sign-in",
  ACCOUNT: "/account",
  OPERATION: "/operation/:id",
  ALL: "*",
};

export const authRoutes = [
  {
    id: 1,
    path: ROUTES.ACCOUNT,
    element: <AccountPage />,
  },
  {
    id: 2,
    path: ROUTES.OPERATION,
    element: <OperationPage />,
  },
];
