import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

const TextButton = ({
  disabled = false,
  onClick,
  loading = false,
  classes = {},
  children,
  ...props
}) => (
  <button
    className={classNames(styles.button, classes.button)}
    type="button"
    disabled={disabled || loading}
    onClick={onClick}
    {...props}
  >
    <span>{children}</span>
  </button>
);

export default TextButton;
