import * as React from "react";

const SvgKey = (props) => (
  <svg
    width={20}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.833 7.5a2.41 2.41 0 0 0 1.771-.73A2.41 2.41 0 0 0 8.334 5a2.41 2.41 0 0 0-.73-1.77 2.41 2.41 0 0 0-1.77-.73 2.41 2.41 0 0 0-1.771.73A2.41 2.41 0 0 0 3.333 5c0 .694.244 1.285.73 1.77a2.41 2.41 0 0 0 1.77.73Zm0 2.5c-1.388 0-2.569-.486-3.541-1.458C1.32 7.569.833 6.389.833 5c0-1.389.487-2.57 1.459-3.542C3.264.486 4.445 0 5.834 0 6.958 0 7.94.32 8.781.958c.84.64 1.427 1.43 1.76 2.375H17.5l1.667 1.625-3.334 3.375-1.666-1.666L12.5 8.333l-1.666-1.666h-.292a4.84 4.84 0 0 1-1.813 2.416A4.885 4.885 0 0 1 5.834 10Z"
      fill="#00C337"
    />
  </svg>
);

export default SvgKey;
