import React from "react";
import { useMediaQuery } from "react-responsive";
import { DesktopVariant, MobileVariant, TabletVariant } from "./variants";

const Menu = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 900 });

  if (isMobile) {
    return <MobileVariant />;
  }

  if (isTablet) {
    return <TabletVariant />;
  }

  return <DesktopVariant />;
};

export default Menu;
