/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedBranch: {
    branchName: "",
    branchConnectAddress: "",
    branchId: "",
    warehouses: [],
  },
  selectedWarehouses: [],
  levelOptions: [{ level: 0, lineId: "" }],
  currentLevel: {
    level: 0,
    lineId: undefined,
  },
};

const resetLevelState = (state) => {
  if (state.currentLevel.level > 0) {
    state.levelOptions = initialState.levelOptions;
    state.currentLevel = initialState.currentLevel;
  }
};

const resetSelectedWarehouses = (state) => {
  state.selectedWarehouses = [];
};

const operations = createSlice({
  name: "operations",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetLevel: (state) => {
      resetLevelState(state);
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload;
      resetSelectedWarehouses(state);
      resetLevelState(state);
    },
    setSelectedWarehouses: (state, action) => {
      if (!state.selectedWarehouses.find((item) => item === action.payload)) {
        state.selectedWarehouses = [
          ...state.selectedWarehouses,
          action.payload,
        ];
      } else {
        state.selectedWarehouses = state.selectedWarehouses.filter(
          (item) => item !== action.payload
        );
      }
      resetLevelState(state);
    },
    incrementLevel: (state, action) => {
      state.levelOptions = [...state.levelOptions, action.payload];
      state.currentLevel = action.payload;
    },
    decrementLevel: (state) => {
      const levelOptions = [...state.levelOptions];
      levelOptions.pop();
      const currentLevel = levelOptions[levelOptions.length - 1];

      state.levelOptions = levelOptions;
      state.currentLevel = currentLevel;
    },
  },
});

export const {
  resetState,
  resetLevel,
  setSelectedBranch,
  setSelectedWarehouses,
  incrementLevel,
  decrementLevel,
} = operations.actions;

export default operations.reducer;

export const selectSelectedBranch = (state) =>
  state.rootReducer.operations.selectedBranch;

export const selectSelectedWarehouses = (state) =>
  state.rootReducer.operations.selectedWarehouses;

export const selectLevelOptions = (state) =>
  state.rootReducer.operations.levelOptions;

export const selectCurrentLevel = (state) =>
  state.rootReducer.operations.currentLevel;
