import classNames from "classnames";
import React from "react";
import { Key as KeyIcon } from "app/assets/icons";
import styles from "./styles.module.scss";

const PasswordInput = React.forwardRef(
  ({ onChange, value, placeholder, errorText, ...props }, ref) => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <KeyIcon className={styles.keyIcon} />
          <input
            className={classNames(styles.input, {
              [styles.inputError]: errorText,
            })}
            type="password"
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            ref={ref}
            {...props}
          />
        </div>
        {errorText && <span className={styles.errorText}>{errorText}</span>}
      </div>
    );
  }
);

export default PasswordInput;
