import { ArrowDropDown } from "app/assets/icons";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";

import styles from "./styles.module.scss";

const MultiDropdown = ({
  defaultToggle = "Выбрать",
  options = [],
  optionKey = "name",
  selectedOption = "",
  isOpen = false,
  onToggle = () => ({}),
  onOptionClicked = () => ({}),
  setIsOpen = () => ({}),
}) => {
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={ref}
      className={classNames(styles.dropdown, { [styles.isOpen]: isOpen })}
    >
      <div
        role="button"
        tabIndex={0}
        className={styles.field}
        onClick={onToggle}
        onKeyDown={() => ({})}
      >
        {selectedOption || defaultToggle}
        <ArrowDropDown className={styles.arrow} />
      </div>
      {isOpen && (
        <div className={styles.listContainer}>
          <div>
            {options.map((option) => (
              <div
                key={option[optionKey]}
                role="button"
                tabIndex={0}
                className={classNames(styles.listItem, {
                  [styles.longText]: option[optionKey].length > 50,
                })}
                onClick={onOptionClicked(option)}
                onKeyDown={() => ({})}
              >
                {option[optionKey]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiDropdown;
