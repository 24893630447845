import { SERVER_ERROR_TEXT } from "shared/constants";
import styles from "./styles.module.scss";

const ServerError = ({ errorText = SERVER_ERROR_TEXT }) => {
  if (errorText) {
    return (
      <div className={styles.errorContainer}>
        <span className={styles.errorText}>{errorText}</span>
      </div>
    );
  }
  return null;
};

export default ServerError;
