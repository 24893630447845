import React from "react";

import { PopupConsumer } from "./PopupContext";

const PopupRoot = () => (
  <PopupConsumer>
    {({ component: Component, props, hidePopup }) =>
      Component ? <Component {...props} onRequestClose={hidePopup} /> : null
    }
  </PopupConsumer>
);

export default PopupRoot;
