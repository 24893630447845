import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

const Button = ({
  disabled = false,
  onClick,
  loading = false,
  classes = {},
  children,
  ...props
}) => (
  <button
    className={classNames(styles.button, classes.button)}
    type="button"
    disabled={disabled || loading}
    onClick={onClick}
    {...props}
  >
    {loading ? "Loading..." : children || "Click!"}
  </button>
);

export default Button;
