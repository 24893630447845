import { ArrowGreen, ArrowRed } from "app/assets/icons";
import { getArrowWithStatus, hexToRgba } from "shared/helpers";

import styles from "./styles.module.scss";

const TopCard = ({ card = {} }) => {
  const { dynamicStatus } = card;
  const { color, rotate } = getArrowWithStatus(dynamicStatus);
  const noSignStatus = dynamicStatus !== "NO_SIGN";
  const renderArrow = () =>
    noSignStatus && (
      <div
        className={styles.arrowIcon}
        style={{ transform: rotate, alignContent: "flex-end" }}
      >
        {color === "red" ? <ArrowRed /> : <ArrowGreen />}
      </div>
    );
  const renderWarn = () =>
    card.status === "WARN" && (
      <div
        className={styles.status}
        style={{
          backgroundColor: card.backgroundColor,
        }}
      >
        <span
          style={{
            color: card.fontColor,
            marginLeft: noSignStatus ? "14%" : 0,
          }}
        >
          {card?.count}
        </span>
        {renderArrow()}
      </div>
    );
  return (
    <div
      style={{
        backgroundColor: hexToRgba(card.color, 0.3),
      }}
      className={styles.wrapper}
    >
      {renderWarn()}
      {card.status !== "WARN" && (
        <>
          <span style={{ marginLeft: noSignStatus ? "14%" : 0 }}>
            {card?.count}
          </span>
          {renderArrow()}
        </>
      )}
    </div>
  );
};

export default TopCard;
