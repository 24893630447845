import * as React from "react";

const SvgArrowDropDown = (props) => (
  <svg
    width={10}
    height={5}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 4.5.833.333h8.333L5 4.5Z" fill="#00C337" />
  </svg>
);

export default SvgArrowDropDown;
