import { uniq } from "lodash";
import { ButtonLink } from "shared/ui";

export const filterFunction = (rows, columnId, filterValue) => {
  return [...rows].filter((item) =>
    filterValue.includes(item.original[columnId].toString())
  );
};

export const getFilterOptions = (id, preFilteredRows) => {
  return uniq(preFilteredRows.map((item) => item.original[id]));
};

export const isLink = (str) => {
  const regex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1>(.*?)<\/a>/i;

  return regex.test(str);
};

export const getLinkAttributes = (str) => {
  const regexHref = /href\s*=\s*["']([^"']+)["']/i;
  const regexTarget = /target\s*=\s*["']([^"']+)["']/i;
  const regexRel = /rel\s*=\s*["']([^"']+)["']/i;
  const regexDownload = /download\s*=\s*["']([^"']+)["']/i;
  const regexReferrerPolicy = /referrerpolicy\s*=\s*["']([^"']+)["']/i;
  const regexText = /<a[^>]*>(.*?)<\/a>/i;

  const href = str.match(regexHref)?.[1] || null;
  const target = str.match(regexTarget)?.[1] || null;
  const rel = str.match(regexRel)?.[1] || null;
  const download = str.match(regexDownload)?.[1] || null;
  const referrerPolicy = str.match(regexReferrerPolicy)?.[1] || null;
  const text = str.match(regexText)?.[1] || null;

  return { href, target, rel, download, referrerPolicy, text };
};

export const formatDataCell = (data) => {
  if (typeof data === "string" && isLink(data)) {
    const { href, target, rel, download, referrerPolicy, text } =
      getLinkAttributes(data);

    return (
      <ButtonLink
        href={href}
        target={target}
        rel={rel}
        download={download}
        referrerPolicy={referrerPolicy}
      >
        {text}
      </ButtonLink>
    );
  }

  return data;
};

export const formatFilterOptions = (options) => {
  if (options.length > 0) {
    const firstElement = options[0];
    if (typeof firstElement === "string") {
      if (/^\d{1,2}\.\d{1,2}\.\d{2,4}$/.test(firstElement)) {
        return options;
      }
      return options.sort((a, b) => a.localeCompare(b));
    }
    if (typeof firstElement === "number") {
      return options.sort((a, b) => a - b);
    }
  }
  return options;
};

export const formatFilterValue = (value) => {
  switch (value) {
    case null:
      return "Пустое значение(null)";
    case "":
      return "Пустая строка";
    default:
      return value;
  }
};
