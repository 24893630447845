import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Logo as LogoIcon } from "app/assets/icons";
import { Button, EmailInput, ErrorText, PasswordInput } from "shared/ui";

import { setAuthToken } from "shared/libs/local-storage";
import { useSignInMutation } from "shared/api/auth";
import { SERVER_ERROR_TEXT } from "shared/constants";
import styles from "./styles.module.scss";
import { SignInSchema, defaultValues } from "./config";

const SignIn = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignInSchema),
    defaultValues,
  });
  const [serverError, setServerError] = useState("");
  const [signIn, { isLoading }] = useSignInMutation();

  const onSignInButtonClick = async (values) => {
    const response = await signIn(values);

    if (response.error) {
      setServerError(response.error?.data?.message || SERVER_ERROR_TEXT);
      return;
    }

    setAuthToken(response.data.token);
    window.location.reload();
  };

  const onClear = () => {
    reset(defaultValues);
    if (serverError) setServerError("");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <LogoIcon />
        <div className={styles.emailContainer}>
          <EmailInput
            {...register("username", {
              onChange: () => serverError && setServerError(""),
            })}
            onClear={onClear}
            disabled={isLoading}
            errorText={errors?.username?.message}
          />
        </div>
        <div className={styles.passwordContainer}>
          <PasswordInput
            {...register("password", {
              onChange: () => serverError && setServerError(""),
            })}
            disabled={isLoading}
            errorText={errors?.password?.message}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant="primary"
            size="large"
            onClick={handleSubmit(onSignInButtonClick)}
            classes={{ button: styles.button }}
            loading={isLoading}
          >
            Войти
          </Button>
        </div>
        <ErrorText errorText={serverError} />
      </div>
    </div>
  );
};

export default SignIn;
