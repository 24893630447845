import moment from "moment";
import { api } from "shared/api";

const operationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listOperations: builder.query({
      query: ({
        branchConnectAddress = "",
        branchId = "",
        warehouseIds = [],
      }) =>
        `operations?branchConnectAddress=${branchConnectAddress}&branchId=${branchId}&warehouseIds=${warehouseIds}`,
      transformResponse: (response) => {
        const dateNow = moment().format("DD.MM HH:mm:ss");

        return {
          list: response.sort((a, b) => a.sortNumber - b.sortNumber),
          date: dateNow,
        };
      },
    }),
    operationDetails: builder.mutation({
      query: ({
        branchConnectAddress,
        detailProcedure,
        level,
        warehouseIds,
        LINEID,
      }) => ({
        url: `operations/details?branchConnectAddress=${branchConnectAddress}&detailProcedure=${detailProcedure}&level=${level}&warehouseIds=${warehouseIds}`,
        method: "POST",
        body: LINEID,
      }),

      transformResponse: (response) => {
        const dateNow = moment().format("DD.MM HH:mm:ss");

        return {
          list: response,
          date: dateNow,
        };
      },
    }),
  }),
});

export const { useListOperationsQuery, useOperationDetailsMutation } =
  operationsApi;
