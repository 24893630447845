import * as React from "react";

const SvgLogout = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.167 15.5c-.459 0-.851-.163-1.178-.49A1.607 1.607 0 0 1 .5 13.834V2.167c0-.459.163-.851.49-1.178C1.315.663 1.707.5 2.166.5H8v1.667H2.167v11.666H8V15.5H2.167Zm9.166-3.333-1.146-1.209 2.126-2.125H5.5V7.167h6.813l-2.126-2.125 1.146-1.209L15.5 8l-4.167 4.167Z"
      fill="#666"
    />
  </svg>
);

export default SvgLogout;
