import * as React from "react";

const SvgArrowRightAlt = (props) => (
  <svg
    width={14}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.666 10 7.5 8.792l2.958-2.959H.333V4.167h10.125L7.5 1.208 8.666 0l5 5-5 5Z"
      fill="#666"
    />
  </svg>
);

export default SvgArrowRightAlt;
