import { Cached } from "app/assets/icons";
import { NavLink } from "react-router-dom";
import { useListOperationsQuery } from "shared/api/operations";
import { ErrorText, LoadingText, RoundButton } from "shared/ui";

import { useSelector } from "react-redux";
import {
  selectSelectedBranch,
  selectSelectedWarehouses,
} from "app/store/reducer/operations";
import styles from "./styles.module.scss";
import { BottomCard, TopCard } from "./ui";

const Account = () => {
  const { branchConnectAddress, branchId } = useSelector(selectSelectedBranch);
  const selectedWarehouses = useSelector(selectSelectedWarehouses);
  const {
    data: operations,
    isFetching,
    refetch,
    isError,
  } = useListOperationsQuery(
    {
      branchConnectAddress,
      branchId,
      warehouseIds: selectedWarehouses,
    },
    {
      skip: !branchConnectAddress || !branchId,
      refetchOnMountOrArgChange: true,
    }
  );

  if (isError) {
    return <ErrorText />;
  }

  return (
    <div className={styles.page}>
      {isFetching ? (
        <LoadingText />
      ) : (
        <div className={styles.cardsList}>
          {operations?.list?.map((item) => (
            <NavLink
              key={item.id}
              to={`/operation/${item.id}`}
              state={{
                detailsProcedureName: item.detailsProcedureName,
                operationName: item.name,
              }}
              className={styles.link}
            >
              <div className={styles.card}>
                <TopCard card={item} />
                <BottomCard card={item} />
              </div>
            </NavLink>
          ))}
        </div>
      )}
      <div className={styles.buttonContainer}>
        <div className={styles.date}>{operations?.date}</div>
        <RoundButton onClick={() => refetch()}>
          <Cached />
        </RoundButton>
      </div>
    </div>
  );
};

export default Account;
