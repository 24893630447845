import * as React from "react";

const SvgArrowRed = (props) => (
  <svg
    height={34}
    width={50}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 600"
    {...props}
  >
    <path fill="none" d="M-1-1h582v402H-1z" />
    <path d="M476.847 216.373 263.513 3.04c-4.267-4.053-10.88-4.053-15.04 0L35.14 216.373c-4.16 4.16-4.16 10.88-.107 15.04 2.027 2.027 4.8 3.2 7.573 3.2h128V501.28c0 5.867 4.8 10.667 10.667 10.667h149.333c5.867 0 10.667-4.8 10.667-10.667V234.613h128c5.867 0 10.667-4.8 10.667-10.667 0-2.879-1.067-5.546-3.093-7.573zm-146.24-3.093c-5.867 0-10.667 4.8-10.667 10.667v266.667h-128V223.947c0-5.867-4.8-10.667-10.667-10.667H68.42L255.94 25.547 443.567 213.28h-112.96z" />
    <path
      d="M67.164 213.333 255.714 23.81l190 190-120.476.476-5.238 6.666.476 269.524H190.952V220.952l-6.666-8.095-117.122.476z"
      strokeOpacity="null"
      strokeWidth={1.5}
      stroke="#000"
      fill="#ff8787"
    />
  </svg>
);

export default SvgArrowRed;
