import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { SignInPage } from "pages";
import { getAuthToken } from "shared/libs/local-storage";
import Menu from "widgets/menu";
import { authRoutes, ROUTES } from "./config";

const Routing = () => {
  const isAuthorized = getAuthToken();

  if (!isAuthorized) {
    return (
      <Router>
        <Routes>
          <Route path={ROUTES.SIGN_IN} element={<SignInPage />} exact />
          <Route path={ROUTES.ALL} element={<Navigate to={ROUTES.SIGN_IN} />} />
        </Routes>
      </Router>
    );
  }
  return (
    <Router>
      <Menu />
      <Routes>
        {authRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={route.element}
            exact
          />
        ))}
        <Route path={ROUTES.ALL} element={<Navigate to={ROUTES.ACCOUNT} />} />
      </Routes>
    </Router>
  );
};

export default Routing;
