import { LogoSymbol } from "app/assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserLogin } from "shared/libs/jwt-decode";
import { SignOutButton } from "shared/ui";
import { SelectBranch, SelectWarehouse, onSignOut } from "widgets/menu/model";
import { ROUTES } from "app/routing/config";
import styles from "./styles.module.scss";

const Mobile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className={styles.menu}>
      <div className={styles.header}>
        <LogoSymbol
          className={styles.logo}
          onClick={() => navigate(ROUTES.ACCOUNT)}
        />
        <SignOutButton onClick={onSignOut}>{getUserLogin()}</SignOutButton>
      </div>
      <div className={styles.filters}>
        <SelectBranch />
        {location?.state?.operationName || "Операции"}
        <SelectWarehouse />
      </div>
    </nav>
  );
};

export default Mobile;
