import Popup from "reactjs-popup";

const styles = {
  width: "auto",
  padding: 20,
  borderRadius: 8,
};

const NewPopup = ({ modal, trigger, children }) => {
  return (
    <Popup
      className={styles.wrapper}
      modal={modal}
      trigger={<div>{trigger}</div>}
      closeOnDocumentClick
      contentStyle={styles}
    >
      {children}
    </Popup>
  );
};

export default NewPopup;
