import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

const ButtonLink = ({ classes = {}, children, ...props }) => (
  <a className={classNames(styles.link, classes.link)} {...props}>
    {children}
  </a>
);

export default ButtonLink;
