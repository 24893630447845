import {
  selectSelectedBranch,
  selectSelectedWarehouses,
  setSelectedBranch,
  setSelectedWarehouses,
} from "app/store/reducer/operations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListBranchesQuery } from "shared/api/branches";
import { clearAuthToken } from "shared/libs/local-storage";
import { Dropdown, MultiDropdown } from "shared/ui";

export const SelectBranch = () => {
  const dispatch = useDispatch();
  const selectedBranch = useSelector(selectSelectedBranch);
  const {
    data: branches,
    isFetching,
    isSuccess,
    isError,
  } = useListBranchesQuery();
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    dispatch(setSelectedBranch(value));
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSuccess && branches.length > 0) {
      dispatch(setSelectedBranch(branches[0]));
    }
  }, [isSuccess, branches]);

  if (isFetching || isError) return null;

  return (
    <Dropdown
      defaultToggle="Выбрать филиал"
      options={branches}
      optionKey="branchName"
      selectedOption={selectedBranch.branchName}
      isOpen={isOpen}
      onToggle={onToggle}
      onOptionClicked={onOptionClicked}
      setIsOpen={setIsOpen}
    />
  );
};

export const SelectWarehouse = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const selectedBranch = useSelector(selectSelectedBranch);
  const selectedWarehouses = useSelector(selectSelectedWarehouses);

  const onToggle = () => setIsOpen(!isOpen);

  const onOptionClicked = (event) => {
    const { value } = event.target;

    dispatch(setSelectedWarehouses(value));
  };

  return (
    <MultiDropdown
      defaultToggle="Выбрать склад"
      options={selectedBranch.warehouses}
      optionKey="warehouseName"
      selectedOptions={selectedWarehouses}
      isOpen={isOpen}
      onToggle={onToggle}
      onOptionClicked={onOptionClicked}
      setIsOpen={setIsOpen}
    />
  );
};

export const onSignOut = () => {
  clearAuthToken();
  window.location.reload();
};
