import {
  formatFilterOptions,
  formatFilterValue,
  getFilterOptions,
} from "pages/operation/utils";
import React from "react";
import { IconButton, TextButton } from "shared/ui";
import { Close as CloseIcon } from "app/assets/icons";

import styles from "./styles.module.scss";

const Filter = ({ tableProps, closePopup = () => {} }) => {
  const { column } = tableProps;
  const { id, setFilter, preFilteredRows, filterValue } = column;
  const filterOptions = getFilterOptions(id, preFilteredRows);

  const onFilterSelect = (event) => {
    const { value } = event.target;
    let newFilterValue = [];

    if (filterValue?.includes(value)) {
      newFilterValue = filterValue.filter((item) => item !== value);
    } else {
      newFilterValue =
        filterValue?.length > 0 ? [...filterValue, value] : [value];
    }

    if (newFilterValue.length > 0) {
      setFilter(newFilterValue);
    } else setFilter();
  };

  const selectAllFiltersInColumns = () => {
    setFilter();
    closePopup();
  };

  const sortedFilterOptions = formatFilterOptions(filterOptions);

  return (
    <div>
      <div className={styles.header}>
        <h1>Фильтры</h1>
        <IconButton onClick={closePopup}>
          <CloseIcon />
        </IconButton>
      </div>
      <TextButton
        classes={{
          button: styles.selectAllButton,
        }}
        onClick={selectAllFiltersInColumns}
      >
        Выбрать все
      </TextButton>
      <div className={styles.listContainer}>
        {sortedFilterOptions?.map((item) => (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label key={item} className={styles.label}>
            <input
              type="checkbox"
              defaultChecked={filterValue?.includes(item.toString())}
              value={item}
              onChange={onFilterSelect}
            />
            <span className={styles.text}>{formatFilterValue(item)}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default Filter;
