import React from "react";
import { Provider } from "react-redux";
import { store } from "./store";

import "./index.scss";
import Routing from "./routing";
import { PopupProvider } from "./context/popup/PopupContext";
import PopupRoot from "./context/popup/PopupRoot";
import "reactjs-popup/dist/index.css";

const App = () => (
  <div className="app">
    <Provider store={store}>
      <PopupProvider>
        <PopupRoot />
        <Routing />
      </PopupProvider>
    </Provider>
  </div>
);

export default App;
