import * as React from "react";

const SvgClose = (props) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.333 11.833.167 10.667 4.833 6 .167 1.333 1.333.167 6 4.833 10.666.167l1.167 1.166L7.166 6l4.667 4.667-1.167 1.166L6 7.167l-4.667 4.666Z"
      fill="#666"
    />
  </svg>
);

export default SvgClose;
