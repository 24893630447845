import { NewPopup, TextButton } from "shared/ui";
import { FilterPopup } from "../../ui";

import styles from "./styles.module.scss";

const FilterModel = (tableProps) => {
  const { column } = tableProps;
  const { Header, filterValue } = column;

  return (
    <div className={styles.field}>
      <NewPopup
        modal
        trigger={
          <TextButton classes={{ button: styles.fieldButton }}>
            {Header}
          </TextButton>
        }
      >
        {(close) => <FilterPopup tableProps={tableProps} closePopup={close} />}
      </NewPopup>
      {filterValue !== undefined && <div className={styles.filterSelected} />}
    </div>
  );
};

export default FilterModel;
