import { hexToRgba } from "shared/helpers";

import styles from "./styles.module.scss";

const BottomCard = ({ card = {} }) => (
  <div
    className={styles.wrapper}
    style={{
      backgroundColor: hexToRgba(card.color, 0.5),
    }}
  >
    <span>{card?.name}</span>
  </div>
);

export default BottomCard;
