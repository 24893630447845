import React from "react";

import styles from "./styles.module.scss";

const IconButton = ({
  disabled = false,
  onClick,
  loading = false,
  children,
  ...props
}) => (
  <button
    className={styles.button}
    type="button"
    disabled={disabled || loading}
    onClick={onClick}
    {...props}
  >
    {children || "Icon"}
  </button>
);

export default IconButton;
