import * as React from "react";

const SvgKeyboardBackspace = (props) => (
  <svg
    width={18}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 12 0 6l6-6 1.4 1.4L3.8 5H18v2H3.8l3.6 3.6L6 12Z"
      fill="#00C337"
    />
  </svg>
);

export default SvgKeyboardBackspace;
