import * as yup from "yup";

export const SignInSchema = yup.object().shape({
  username: yup
    .string()
    .email("Неправильный e-mail")
    .required("Обязательное поле"),
  password: yup.string("Неправильный пароль").required("Обязательное поле"),
});

export const defaultValues = {
  username: "",
  password: "",
};
