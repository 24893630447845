import { ArrowRightAlt, MenuLogo } from "app/assets/icons";
import { useLocation, useNavigate } from "react-router-dom";

import { ROUTES } from "app/routing/config";
import { SelectBranch, SelectWarehouse, onSignOut } from "widgets/menu/model";
import { SignOutButton } from "shared/ui";
import { getUserLogin } from "shared/libs/jwt-decode";
import styles from "./styles.module.scss";

const Desktop = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className={styles.menu}>
      <div className={styles.logoFlex}>
        <MenuLogo
          className={styles.logo}
          onClick={() => navigate(ROUTES.ACCOUNT)}
        />
      </div>
      <div className={styles.flowFlex}>
        <SelectBranch />
        <ArrowRightAlt className={styles.arrowIcon} />
        <SelectWarehouse />
        <ArrowRightAlt className={styles.arrowIcon} />
        {location?.state?.operationName || "Операции"}
      </div>
      <div className={styles.buttonFlex}>
        <SignOutButton onClick={onSignOut}>{getUserLogin()}</SignOutButton>
      </div>
    </nav>
  );
};

export default Desktop;
