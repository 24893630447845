import { Cached, KeyboardBackspace } from "app/assets/icons";
import {
  resetLevel,
  selectCurrentLevel,
  selectLevelOptions,
  selectSelectedBranch,
  incrementLevel,
  decrementLevel,
  selectSelectedWarehouses,
} from "app/store/reducer/operations";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useOperationDetailsMutation } from "shared/api/operations";
import { Button, ErrorText, LoadingText, RoundButton } from "shared/ui";
import Table from "widgets/table";
import styles from "./styles.module.scss";
import FilterModel from "./model/filter";
import { filterFunction, formatDataCell } from "./utils";

const Operation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { branchConnectAddress } = useSelector(selectSelectedBranch);
  const { level, lineId } = useSelector(selectCurrentLevel);
  const levelOptions = useSelector(selectLevelOptions);
  const selectedWarehouses = useSelector(selectSelectedWarehouses);
  const [operationDetails, setOperationDetails] = useState([]);
  const [fetchOperationDetail, { isLoading, isError }] =
    useOperationDetailsMutation();

  const onRowClick = (newLineId) => {
    if (newLineId) {
      dispatch(
        incrementLevel({
          level: level + 1,
          lineId: newLineId,
        })
      );
    }
  };

  const onBackClick = () => {
    if (levelOptions.length > 1) {
      dispatch(decrementLevel());
    } else navigate("/account");
  };

  const data = React.useMemo(
    () => operationDetails?.list,
    [operationDetails?.list]
  );

  const columns = React.useMemo(() => {
    if (operationDetails?.list && operationDetails?.list?.length) {
      return Object.keys(operationDetails.list[0])
        .filter((key) => key !== "FontColor" && key !== "BackgroundColor")
        .map((item) => {
          if (item === "LINEID") {
            return {
              id: item,
              Header: item,
              // eslint-disable-next-line react/no-unstable-nested-components
              accessor: (value) => (
                <Button onClick={() => onRowClick(value[item])}>{">>>"}</Button>
              ),
              Filter: () => {},
              filter: () => {},
            };
          }
          return {
            id: item,
            Header: item,
            accessor: (value) => formatDataCell(value[item]),
            Filter: FilterModel,
            filter: filterFunction,
          };
        });
    }

    return [];
  }, [operationDetails?.list]);

  const fetchData = async () => {
    const response = await fetchOperationDetail({
      branchConnectAddress,
      detailProcedure: location.state.detailsProcedureName,
      level,
      warehouseIds: selectedWarehouses,
      LINEID: lineId,
    });

    setOperationDetails(response.data);
  };

  useEffect(() => {
    if (branchConnectAddress && location.state.detailsProcedureName) {
      fetchData();
    }
  }, [
    branchConnectAddress,
    location.state.detailsProcedureName,
    level,
    selectedWarehouses,
    lineId,
  ]);

  useEffect(() => {
    return () => dispatch(resetLevel());
  }, []);

  if (isError) {
    return <ErrorText />;
  }

  return (
    <div className={styles.page}>
      {isLoading ? (
        <LoadingText />
      ) : (
        <Table data={data} columns={columns} onRowClick={onRowClick} />
      )}
      <div className={styles.buttonContainer}>
        <div className={styles.date}>{operationDetails?.date}</div>
        <div className={styles.buttons}>
          <RoundButton
            variant="secondary"
            classes={{
              button: styles.backButton,
            }}
            onClick={onBackClick}
          >
            <KeyboardBackspace />
          </RoundButton>
          <RoundButton onClick={fetchData}>
            <Cached />
          </RoundButton>
        </div>
      </div>
    </div>
  );
};

export default Operation;
