import memoizeOne from "memoize-one";
import React, { Component, createContext } from "react";

export const PopupContext = createContext({
  component: null,
  props: {},
  showPopup: () => ({}),
  hidePopup: () => ({}),
});

export class PopupProvider extends Component {
  memo = memoizeOne((value) => value);

  constructor(props) {
    super(props);
    this.state = {
      component: null,
      props: {},
      showPopup: this.showPopup,
      hidePopup: this.hidePopup,
    };
  }

  showPopup = (component, props = {}) => {
    this.setState({
      component,
      props,
    });
  };

  hidePopup = () => {
    this.setState({
      component: null,
      props: {},
    });
  };

  render() {
    const { children } = this.props;
    const { component, props, showPopup, hidePopup } = this.state;
    const memoizedContext = this.memo({
      component,
      props,
      showPopup,
      hidePopup,
    });

    return (
      <PopupContext.Provider value={memoizedContext}>
        {children}
      </PopupContext.Provider>
    );
  }
}

export const PopupConsumer = PopupContext.Consumer;
