/* eslint-disable jsx-a11y/label-has-associated-control */
import { ArrowDropDown } from "app/assets/icons";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";

import styles from "./styles.module.scss";

const Dropdown = ({
  defaultToggle = "Выбрать",
  options = [],
  optionKey = "name",
  selectedOptions = [],
  isOpen = false,
  onToggle = () => ({}),
  onOptionClicked = () => ({}),
  setIsOpen = () => ({}),
}) => {
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={ref}
      className={classNames(styles.dropdown, { [styles.isOpen]: isOpen })}
    >
      <div
        role="button"
        tabIndex={0}
        className={styles.field}
        onClick={onToggle}
        onKeyDown={() => ({})}
      >
        {selectedOptions.length > 0
          ? `Выбрано ${selectedOptions.length}`
          : defaultToggle}
        <ArrowDropDown className={styles.arrow} />
      </div>
      {isOpen && (
        <div className={styles.listContainer}>
          <div>
            {options.map((option) => (
              <label
                key={option[optionKey]}
                className={classNames(styles.listItem, {
                  [styles.longText]: option[optionKey].length > 50,
                })}
              >
                <input
                  type="checkbox"
                  defaultChecked={selectedOptions?.includes(option.id)}
                  value={option.id}
                  onChange={onOptionClicked}
                />
                <span>{option[optionKey]}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
