import * as React from "react";

const SvgAccountCircle = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.875 13.25a8.704 8.704 0 0 1 2.375-1.282A8.122 8.122 0 0 1 9 11.5c.958 0 1.875.156 2.75.468.875.313 1.666.74 2.375 1.282a6.45 6.45 0 0 0 1.136-1.938c.27-.722.405-1.493.405-2.312 0-1.847-.649-3.42-1.947-4.72-1.299-1.298-2.872-1.947-4.72-1.947-1.846 0-3.42.65-4.718 1.948C2.984 5.58 2.334 7.153 2.334 9c0 .82.136 1.59.407 2.313.27.722.649 1.367 1.135 1.937ZM9 9.833c-.82 0-1.51-.28-2.074-.843-.562-.563-.843-1.254-.843-2.073 0-.82.281-1.51.843-2.074C7.49 4.281 8.18 4 9 4c.82 0 1.51.281 2.073.843.562.563.843 1.254.843 2.074 0 .82-.28 1.51-.843 2.073-.563.562-1.254.843-2.073.843Zm0 7.5a8.111 8.111 0 0 1-3.25-.656 8.411 8.411 0 0 1-2.646-1.781 8.41 8.41 0 0 1-1.78-2.646A8.11 8.11 0 0 1 .666 9a8.11 8.11 0 0 1 .656-3.25 8.41 8.41 0 0 1 1.781-2.646A8.421 8.421 0 0 1 5.75 1.322 8.12 8.12 0 0 1 9 .667c1.153 0 2.236.218 3.25.655a8.421 8.421 0 0 1 2.646 1.782 8.41 8.41 0 0 1 1.78 2.646A8.11 8.11 0 0 1 17.334 9a8.11 8.11 0 0 1-.656 3.25 8.409 8.409 0 0 1-1.781 2.646 8.411 8.411 0 0 1-2.646 1.78 8.111 8.111 0 0 1-3.25.657Z"
      fill="#00C337"
    />
  </svg>
);

export default SvgAccountCircle;
