import { Logout as LogoutIcon } from "app/assets/icons";
import React from "react";

import styles from "./styles.module.scss";

const SignOutButton = ({
  disabled = false,
  onClick,
  loading = false,
  children,
  ...props
}) => (
  <button
    className={styles.button}
    type="button"
    disabled={disabled || loading}
    onClick={onClick}
    {...props}
  >
    <span>{children || "Выйти"}</span>
    <LogoutIcon />
  </button>
);

export default SignOutButton;
