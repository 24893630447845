import { api } from "shared/api";

const branchesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listBranches: builder.query({
      query: () => "branches",
    }),
  }),
});

export const { useListBranchesQuery } = branchesApi;
